import * as React from 'react';
import {
  HiOutlineChevronRight,
} from 'react-icons/hi';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {Message, Loader, Grid, TextField, IconButton, GroupsTable} from '../../components';
import {useAuth, useGroups} from "../../state-management";
import './styles.scss';

interface GroupsProps {
  history: any
  previous: {
    location: string
  }
}

const Groups: React.FC<GroupsProps & RouteComponentProps> = ({ history, previous }) => {
  
  return (
    <Grid
      items={[
        {
          id: 'lake-games--signup',
          cols: { xs: 12, sm: 10, md: 12, lg: 10 },
          content: <GroupsTable/>,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Groups);
