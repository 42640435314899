import * as React from 'react';
import './styles.scss';

const HomeHeader: React.FC = () => {
    return (
        <>
            <div className="jumbotron bg-cover text-white">
                <div className="container py-5 text-center">
                    <h2 className="display-4 font-weight-bold">TP2 is a tool to determine how a group of people can
                        accurately pay each other after a zero sum game</h2>
                </div>
            </div>
        </>
    );
};

export default HomeHeader;
