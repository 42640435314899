import { useEffect, useContext } from 'react';
import { Context, GroupsState } from './context';
import useHttp from "../http";
import {Group, GroupResponse, User} from "../types";
import {AxiosResponse} from "axios";

export interface UseGroups {
  (): {
    getGroups: () => Promise<AxiosResponse>;
    
    getGroupMembers: (groupId: string) => Promise<AxiosResponse>;

    createGroup: (name: string, allowEmail?: boolean, allowSms?: boolean, useSheets?: boolean) => Promise<AxiosResponse>;
    
    updateGroup: (groupId: number, name?: string, allowEmail?: boolean, allowSms?: boolean, useSheets?: boolean) => Promise<AxiosResponse>;
    
    deleteGroup: (groupId: string) => Promise<AxiosResponse<any, any> | void>;
  
    addGroupMember: (groupId: string, username: string, email: string, phone: string) => Promise<AxiosResponse>;
    
    updateGroupMember: (memberId: number, username: string, email: string, phone: string) => Promise<AxiosResponse>;
    
    removeGroupMember: (memberId: string) => Promise<AxiosResponse<any, any> | void>;

    refresh: () => Promise<void>;

    state: GroupsState;
  };
}

export const useGroups: UseGroups = () => {
  const { state, dispatch } = useContext(Context);
  const http = useHttp();
  
  useEffect(() => {
    const user = JSON.parse(String(localStorage.getItem("user") ?? "{}")) as User
    if (user?.id)
      getGroups().catch(err => console.log(err))
  }, [])
  
  const getGroups = async (): Promise<AxiosResponse> => {
    dispatch({
      type: 'START_GROUPS',
    });
    
    return http.getData('groups')
    .then((res) => {
      console.log('GROUPS RESULTS:', res?.data?.results)
      dispatch({
        type: 'SET_GROUPS',
        groups: res?.data?.results,
        currentGroup: res?.data?.results[0]
      });
      return res;
    })
    .catch(({ error }) => {
      dispatch({
        type: 'ERROR_GROUPS',
        error,
      });
      return error;
    });
  };
  
  const getGroupMembers = async (groupId: string): Promise<AxiosResponse> => {
    return http.getData(`members/${groupId}`)
      .then((res) => {
        return res
      })
      .catch(({ error }) => {
        dispatch({
          type: 'ERROR_GROUPS',
          error,
        });
        return error;
      });
  };
  
  const createGroup = async (name: string, allowEmail?: boolean, allowSms?: boolean, useSheets?: boolean): Promise<AxiosResponse> => {
    
    const data = {
      name,
      allow_email: allowEmail,
      allow_sms: allowSms,
      use_sheets: useSheets,
    }
    
    dispatch({
      type: 'START_GROUPS',
    });
    
    return http.postData('groups', data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: 'CREATE_GROUP',
          group: res.data
        });
      }
      else {
        dispatch({
          type: 'ERROR_GROUPS',
          error: res?.data?.error,
        });
      }
      return res
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_GROUPS',
        error,
      });
    });
  };
  
  const updateGroup = async (groupId: number, name?: string, allowEmail?: boolean, allowSms?: boolean, useSheets?: boolean): Promise<AxiosResponse> => {
    
    const data = {}
    if (name) { // @ts-ignore
      data.name = name
    }
    if (allowEmail !== undefined) { // @ts-ignore
      data.allow_email = allowEmail
    }
    if (allowSms !== undefined) { // @ts-ignore
      data.allow_sms = allowSms
    }
    if (useSheets !== undefined) { // @ts-ignore
      data.use_sheets = useSheets
    }
    
    dispatch({
      type: 'START_GROUPS',
    });

    return http.patchData(`groups/${groupId}`, data)
      .then((res) => {
        console.log("PATCH GROUP RES:", res)
        if (res?.status === 200) {
          dispatch({
            type: 'CREATE_GROUP',
            group: res.data
          });
        }
        else {
          dispatch({
            type: 'ERROR_GROUPS',
            error: res?.data?.error,
          });
        }
        return res
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_GROUPS',
          error,
        });
        return error
      });
  };
  
  const deleteGroup = async (groupId: string): Promise<AxiosResponse<any, any> | void> => {
    dispatch({
      type: 'START_GROUPS',
    });
    
    return http.deleteData(`groups/${groupId}`)
    .then((res) => {
      console.log('DELETE GROUP RES:', res)
      return res
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_GROUPS',
        error,
      });
    });
  };
  
  const addGroupMember = async (groupId: string, username: string, email?: string, phone?: string): Promise<AxiosResponse> => {
    
    const data = {
      group_id: parseInt(groupId),
      username,
      email,
      phone
    }
    
    dispatch({
      type: 'START_GROUPS',
    });
    
    return http.postData('members', data)
    .then((res) => {
      console.log('MEMBER RES:', res)
      if (res?.status === 201) {
        dispatch({
          type: 'ADD_MEMBER',
          msg: res.data?.msg
        });
      }
      else {
        dispatch({
          type: 'ERROR_GROUPS',
          error: res.data?.error,
        });
      }
      return res
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_GROUPS',
        error,
      });
    });
  };
  
  const updateGroupMember = async (memberId: number, username: string, email?: string, phone?: string): Promise<AxiosResponse> => {
    
    const data = {
      username,
      email,
      phone
    }
    
    dispatch({
      type: 'START_GROUPS',
    });
    
    return http.patchData(`members/${memberId}`, data)
    .then((res) => {
      console.log('MEMBER PATCH RES:', res)
      if (res?.status === 200) {
        dispatch({
          type: 'UPDATE_MEMBER',
          msg: res.data?.msg
        });
      }
      else {
        dispatch({
          type: 'ERROR_GROUPS',
          error: res.data?.error,
        });
      }
      return res
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_GROUPS',
        error,
      });
    });
  };
  
  const removeGroupMember = async (memberId: string): Promise<AxiosResponse<any, any> | void> => {
    dispatch({
      type: 'START_GROUPS',
    });
    
    return http.deleteData(`members/${memberId}`)
      .then((res) => {
        console.log('DELETE MEMBER RES:', res)
        return res
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_GROUPS',
          error,
        });
      });
  };
  
  const refresh = async (): Promise<void> => {
    dispatch({
      type: 'RESET_GROUPS',
    });
  };

  return {
    getGroups,
    getGroupMembers,
    createGroup,
    updateGroup,
    deleteGroup,
    addGroupMember,
    updateGroupMember,
    removeGroupMember,
    refresh,
    state,
  };
};
