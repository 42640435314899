import { useContext } from 'react';
import { Context, AuthState, UserPayload } from './context';
import useHttp from "../http";

export interface UseAuth {
  (): {
    getUser: (userId: string) => Promise<UserPayload>;

    login: (username: string, email: string, password: string) => Promise<void>;

    refresh: () => Promise<void>;

    signup: (
      username: string,
      pw1: string,
      pw2: string,
      email: string,
      phone: string
    ) => Promise<void>;

    changePassword: (
      oldPassword: string,
      newPassword: string,
      confirmPassword: string
    ) => Promise<void>;

    forgotPassword: (email: string) => Promise<void>;

    supportEmail: (message: string) => Promise<void>;

    uploadProfile: (file: File | undefined) => Promise<void>;
    
    changeUsername: (username: string) => Promise<void>;

    logout: () => Promise<void>;

    state: AuthState;
  };
}

export const useAuth: UseAuth = () => {
  const { state, dispatch } = useContext(Context);
  const http = useHttp();

  const getUser = async (userId: string): Promise<UserPayload> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return http.getData(`users/${userId}`)
      .then((res) => {
        if (res?.data?.results?.id)
          dispatch({
            type: 'SET_AUTHENTICATION',
            logname: res.data.results.username,
            user: res.data.results,
            token: localStorage.getItem("token") ?? ''
          });
        else
          dispatch({
            type: 'ERROR',
            error: '',
          });
      })
      .catch(({ error }) => {
        dispatch({
          type: 'ERROR',
          error,
        });
        return error;
      });
  };

  const login = async (username: string, email: string, password: string): Promise<void> => {
    
    const data = {
      username: username.toLowerCase(),
      email,
      password
    }
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return http.postData('login', data)
      .then((res) => {
        setTimeout(() => {
          if (res.status === 200) {
            dispatch({
              type: 'SET_AUTHENTICATION',
              logname: username.toLowerCase(),
              user: res?.data.user,
              token: res.data.token,
            });
          }
          else {
            dispatch({
              type: 'ERROR',
              error: res?.data,
            });
          }
        }, 500)
        return res
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error,
        });
      });
  };

  const refresh = async (): Promise<void> => {
    dispatch({
      type: 'RESET_AUTHENTICATION',
    });
  };

  const signup = async (
    username: string,
    pw1: string,
    pw2: string,
    email: string,
    phone: string,
  ): Promise<void> => {
    
    const data = {
      username: username.toLowerCase(),
      pw1,
      pw2,
      email,
      phone
    }
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return http.postData('signup', data)
      .then((res) => {
        setTimeout(() => {
          if (res.status === 201) {
            dispatch({
              type: 'SET_AUTHENTICATION',
              logname: username.toLowerCase(),
              user: res.data?.user,
              token: res.data?.token,
            });
          }
          else {
            dispatch({
              type: 'ERROR',
              error: res?.data,
            });
          }
        }, 500)
        return res;
      })
      .catch((error) =>
        dispatch({
          type: 'ERROR',
          error,
        })
      );
  };

  const changeUsername = async (username: string): Promise<void> => {
    const formData = new FormData();
    formData.append('username', username.toLowerCase());

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return http.patchData('user', formData)
      .then((res: any) => {
        if (res?.status !== 200) {
          dispatch({
            type: 'ERROR',
            error: res?.data,
          });
          return res
        }
        dispatch({
          type: 'SET_AUTHENTICATION',
          logname: username.toLowerCase(),
          user: res.data.user,
          token: res.data.token
        });
        return res;
      })
      .catch((error) => {
        console.log('CHANGE USERNAME ERROR:', error);
      });
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
): Promise<void> => {
    const formData = new FormData();
    formData.append('oldPw', oldPassword);
    formData.append('pw1', newPassword);
    formData.append('pw2', confirmPassword);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return http.postData('auth/password/change', formData)
      .then((res) => {
        if (res?.status !== 200) {
          dispatch({
            type: 'ERROR',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('CHANGE PASSWORD ERROR:', error);
      });
  };

  const forgotPassword = async (email: string): Promise<void> => {
    const formData = new FormData();
    formData.append('email', email);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return http.postData('auth/password/reset', formData)
      .then((res) => {
        if (res?.status !== 201) {
          dispatch({
            type: 'ERROR',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('FORGOT PASSWORD ERROR:', error);
      });
  };


  const uploadProfile = async (file: File | undefined): Promise<void> => {
    const formData = new FormData();
    formData.append('filename', file || '');

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return http.postData('profiles', formData)
      .then((res) => {
        if (res?.status !== 201) {
          dispatch({
            type: 'ERROR',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('UPLOAD POST ERROR:', error);
      });
  };
  
  const supportEmail = async (message: string): Promise<void> => {
    dispatch({
      type: 'START_SUPPORT_EMAIL',
    });

    const data = {
      message
    }

    return http.postData('support', data)
      .then(({ msg }) => {
        dispatch({
          type: 'SUPPORT_EMAIL',
          msg
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
  };

  const logout = async (): Promise<void> => {
    dispatch({
      type: 'CLEAR_AUTHENTICATION',
    });

    return http.postData('api/logout', {}).then(() =>
      window.location.reload()
    );
  };

  return {
    getUser,
    login,
    refresh,
    signup,
    changeUsername,
    supportEmail,
    uploadProfile,
    changePassword,
    forgotPassword,
    logout,
    state,
  };
};
