import { createContext } from 'react';
import { AuthDispatch } from './reducer';
import {
  User,
} from '../types';

export interface AuthState {
  logname: string;
  user: User | undefined;
  exp: string;
  error: any;
  msg: string;
  token: string;
  loading: boolean;
  isAuthenticated: boolean;
}

export interface UserPayload {
  previous: null;
  results: User[];
}

export interface AuthContextState {
  state: AuthState;
  dispatch: AuthDispatch;
}

export const defaultState = (): AuthState => ({
  logname: '',
  user: undefined,
  exp: '',
  error: '',
  msg: '',
  token: '',
  loading: false,
  isAuthenticated: false,
});

export const Context = createContext<AuthContextState>({
  state: defaultState(),
  dispatch: () => null,
});
