import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss'

import { DataTable } from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import {useGroups} from "../../state-management";
import {GroupResponse} from "../../state-management/types";
import {getTimeDiff} from "../../utils";
import {Loader, Message} from "../index";
import {Checkbox} from "primereact/checkbox";
import {Badge} from "primereact/badge";
import {AxiosResponse} from "axios";


const GroupsTable: React.FC = () => {
  const { state, getGroups, createGroup, updateGroup, deleteGroup } = useGroups()
  
  const [groupList, setGroupList] = useState<GroupResponse[]>([]);
  const [groupDialog, setGroupDialog] = useState(false);
  const [deleteGroupDialog, setDeleteGroupDialog] = useState(false);
  const [deleteGroupsDialog, setDeleteGroupsDialog] = useState(false);
  const [group, setGroup] = useState<GroupResponse>();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  
  const [name, setName] = useState<string>('');
  const [allowEmail, setAllowEmail] = useState<boolean>(false);
  const [allowSms, setAllowSms] = useState<boolean>(false);
  const [useSheets, setUseSheets] = useState<boolean>(false);
  
  const [err, setErr] = useState<string>('');
  
  
  React.useEffect(() => {
    getGroups().then((res: AxiosResponse) => {
      setGroupList(res?.data?.results ?? [])
    })
  }, [])
  
  const openNew = () => {
    setGroupDialog(true);
    setErr('')
  }
  
  const hideDialog = () => {
    setGroupDialog(false);
    setGroup(undefined);
    setErr('')
    setName('')
    setAllowEmail(false);
    setAllowSms(false);
    setUseSheets(false);
  }
  
  const hideDeleteGroupDialog = () => {
    setDeleteGroupDialog(false);
  }
  
  const hideDeleteGroupsDialog = () => {
    setDeleteGroupsDialog(false);
  }
  
  const saveGroup = async () => {
  
    let _groupList = [...groupList];
    let _group = {...group} as unknown as GroupResponse
    
    if (group?.group?.id) {
      const index = findIndexById(group?.group?.id ?? 0);
      
      _groupList[index] = _group;
      if (toast?.current) {
        const res = await updateGroup(group.group.id, name, allowEmail, allowSms, useSheets)
        if (res?.status === 200) {
          hideDialog()
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Group Updated', life: 3000 });
        }
        else {
          setErr(res?.data?.error)
          // @ts-ignore
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Updating Group', life: 3000 });
        }
      }
    }
    else {
      if (name) {
        const res = await createGroup(name, allowEmail, allowSms, useSheets)
        if (res?.status === 201) {
          _group.group = res.data?.results
          _groupList.unshift(_group)
          setGroupList(_groupList);
          hideDialog()
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Group Created', life: 3000 });
        }
        else {
          console.log('ERROR:', res?.data?.error)
          setErr(res?.data?.error || '')
        }
      }
      else if (!name) {
        setErr("Group name is required")
        return
      }
    }
  }
  
  const editGroup = (group: any) => {
    setGroup({...group});
    setGroupDialog(true);
  }
  
  const confirmDeleteGroup = (group: any) => {
    setGroup(group);
    setDeleteGroupDialog(true);
  }
  
  const handleDeleteGroup = async () => {
    let _groupList = groupList.filter(val => val?.group?.id !== group?.group?.id);
    setGroupList(_groupList);
    setDeleteGroupDialog(false);
    if (toast?.current) {
      const res = await deleteGroup(String(group?.group?.id))
      // @ts-ignore
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Group Deleted', life: 3000 });
      setGroup(undefined);
    }
  }
  
  const findIndexById = (id: number) => {
    let index = 0;
    for (let i = 0; i < groupList.length; i++) {
      if (groupList[i].group.id === id) {
        index = i;
        break;
      }
    }
    
    return index;
  }
  
  const exportCSV = () => {
    // @ts-ignore
    dt?.current?.exportCSV();
  }
  
  const confirmDeleteSelected = () => {
    setDeleteGroupsDialog(true);
  }
  
  const deleteSelectedGroups = () => {
    let _groupList = groupList.filter((val: GroupResponse) => {
      // @ts-ignore
      return !selectedGroups?.includes(val);
    });
    setGroupList(_groupList);
    setDeleteGroupsDialog(false);
    setSelectedGroups([]);
    if (toast?.current) {
      // @ts-ignore
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Groups Deleted (but not actually)', life: 3000 });
    }
  }
  
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
        <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedGroups || !selectedGroups?.length} />
      </React.Fragment>
    )
  }
  
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
      </React.Fragment>
    )
  }
  
  const nameBodyTemplate = (rowData: GroupResponse) => {
    return rowData.group?.name
  }
  
  const emailEnabledBodyTemplate = (rowData: GroupResponse) => {
    const status = rowData.group?.allow_email ? "ENABLED" : "DISABLED"
    const severity = rowData.group?.allow_email ? "success" : "danger"
    return <Badge severity={severity} value={status}/>
  }
  
  const smsEnabledBodyTemplate = (rowData: GroupResponse) => {
    const status = rowData.group?.allow_sms ? "ENABLED" : "DISABLED"
    const severity = rowData.group?.allow_sms ? "success" : "danger"
    return <Badge severity={severity} value={status}/>
  }
  
  const sheetsEnabledBodyTemplate = (rowData: GroupResponse) => {
    const status = rowData.group?.use_sheets ? "ENABLED" : "DISABLED"
    const severity = rowData.group?.use_sheets ? "success" : "danger"
    return <Badge severity={severity} value={status}/>
  }
  
  const createdBodyTemplate = (rowData: GroupResponse) => {
    return getTimeDiff(rowData.group?.CreatedAt)
  }
  
  const actionBodyTemplate = (rowData: GroupResponse) => {
    return (
      <React.Fragment>
        <Link className="plain-link" to={`/members/${rowData?.group?.id}`}>
          <Button icon="pi pi-search" className="p-button-rounded p-button-secondary mr-2"/>
        </Link>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editGroup(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteGroup(rowData)} />
      </React.Fragment>
    );
  }
  
  const header = (
    <div className="table-header">
      <h5 className="mx-0 my-1">Manage Groups</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => {
          // @ts-ignore
          setGlobalFilter(e.target.value)
        }} placeholder="Search..." />
      </span>
    </div>
  );
  
  const groupDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Create" icon="pi pi-check" className="p-button-text" onClick={saveGroup} />
    </React.Fragment>
  );
  
  const deleteGroupDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteGroupDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={handleDeleteGroup} />
    </React.Fragment>
  );
  
  const deleteGroupsDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteGroupsDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedGroups} />
    </React.Fragment>
  );
  
  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />
      
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate} title={'Groups'} right={rightToolbarTemplate}/>
        
        <DataTable ref={dt} value={groupList} selection={selectedGroups} onSelectionChange={(e) => setSelectedGroups(e.value)}
                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Groups"
                   globalFilter={globalFilter} header={header} responsiveLayout="scroll">
          <Column selectionMode="multiple" headerStyle={{width: '3rem'}} exportable={false}/>
          <Column header="Name" body={nameBodyTemplate} sortable style={{minWidth: '6rem'}}/>
          <Column header="Email" body={emailEnabledBodyTemplate} sortable style={{minWidth: '6rem'}}/>
          <Column header="SMS" body={smsEnabledBodyTemplate} sortable style={{minWidth: '6rem'}}/>
          <Column header="GSheets" body={sheetsEnabledBodyTemplate} sortable style={{minWidth: '6rem'}}/>
          <Column header="Created" body={createdBodyTemplate} sortable style={{minWidth: '4rem'}}/>
          <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}/>
        </DataTable>
      </div>
      
      <Dialog visible={groupDialog} style={{ width: '450px' }} header="Create Group" modal className="p-fluid" footer={groupDialogFooter} onHide={hideDialog}>
        
        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText id="name" value={name} autoFocus onChange={(e) => setName(e?.target?.value ?? '')} />
        </div>
        
        <div className="col-12">
          <Checkbox inputId="cb1" value="Enable Email Notifications" onChange={(e) => setAllowEmail(e.checked)} checked={allowEmail}/>
          <label htmlFor="cb1" className="p-checkbox-label">Enable Email Notifications</label>
        </div>
        
        <div className="col-12">
          <Checkbox inputId="cb1" value="Enable Email Notifications" onChange={(e) => setAllowSms(e.checked)} checked={allowSms}/>
          <label htmlFor="cb1" className="p-checkbox-label">Enable SMS Notifications</label>
        </div>
        
        <div className="col-12">
          <Checkbox inputId="cb1" value="Enable Google Sheets" onChange={(e) => setUseSheets(e.checked)} checked={useSheets}/>
          <label htmlFor="cb1" className="p-checkbox-label">Enable Google Sheets Integration</label>
        </div>
        
        <Loader loading={state.loading} />
        <Message message={err} severity={'error'}/>
      </Dialog>
      
      <Dialog visible={deleteGroupDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteGroupDialogFooter} onHide={hideDeleteGroupDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {group && <span>Are you sure you want to delete group <b>{group.group.name}</b>?</span>}
        </div>
      </Dialog>
      
      <Dialog visible={deleteGroupsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteGroupsDialogFooter} onHide={hideDeleteGroupsDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {group && <span>Are you sure you want to delete the selected list of groups?</span>}
        </div>
      </Dialog>
    </div>
  );
};

export default GroupsTable;
