import * as React from 'react';
import {
  HiOutlineQuestionMarkCircle,
  HiOutlineChevronRight,
  HiClipboardCheck,
} from 'react-icons/hi';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import { Message, Loader, Grid, TextField, IconButton } from '../../components';
import { useAuth } from '../../state-management';
import {CURRENT_URL, LIFECYCLE} from '../../utils';
import './styles.scss';

interface SignupProps {
  previous: {
    location: string;
  };
}

const Signup: React.FC<SignupProps & RouteComponentProps> = ({
  history,
  previous,
}) => {
  
  const { state, signup } = useAuth()
  
  const [hideForm, setHideForm] = React.useState<boolean>(false);

  const [username, setUsername] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [password2, setPassword2] = React.useState<string>('');
  
  
  const onSignup = async () => {
    await signup(username, password, password2, email, phone)
  };

  const signupForm = (
    <div className='lake-games--signup'>
      <div className='lake-games--signup-header'>Signup</div>

      <Message
        severity={state.error ? 'error' : 'success'}
        message={state.error || state.msg}
      />
  
      {!hideForm ? (
        <>
          <TextField
            value={username}
            id='username'
            label='Username'
            autoComplete='username'
            onChange={(e) => setUsername(e.target.value)}
            handleSubmit={onSignup}
          />
  
        <TextField
          value={email}
          id='email'
          label='Email'
          autoComplete='email'
          type='email'
          onChange={(e) => setEmail(e.target.value)}
          handleSubmit={onSignup}
        />
        
        <TextField
          value={phone}
          id='phone'
          label='Phone Number'
          type='phone'
          onChange={(e) => setPhone(e.target.value)}
          handleSubmit={onSignup}
        />
  
        <TextField
          value={password}
          id='password'
          label='Password'
          type='password'
          onChange={(e) => setPassword(e.target.value)}
          handleSubmit={onSignup}
        />
  
        <TextField
          value={password2}
          id='password2'
          label='Confirm Password'
          type='password'
          onChange={(e) => setPassword2(e.target.value)}
          handleSubmit={onSignup}
        />
      </>
      ): null}

      <div className='lake-games--signup-actions'>
        <Loader loading={state.loading} />
        <IconButton
          tooltipTitle='Signup'
          icon={HiOutlineChevronRight}
          onClick={onSignup}
          disabled={
            hideForm ||
            (!username.length && !email.length) ||
            !password.length ||
            !password2.length
          }
        />
        <IconButton
          tooltipTitle='Forgot Password'
          icon={HiOutlineQuestionMarkCircle}
          onClick={() => history.push('/forgot')}
        />
        <IconButton
          tooltipTitle='Login'
          icon={HiClipboardCheck}
          onClick={() => history.push('/login')}
        />
        {LIFECYCLE !== "prod" ? (
          <a className='google-login' href={`${CURRENT_URL}accounts/google/login/?process=login`}>
            <GoogleButton />
          </a>
        ) : null}

      </div>
    </div>
  );

  React.useEffect(() => {
    if (state.isAuthenticated) {
      history.push(previous?.location || '/home');
    }
  }, [state.isAuthenticated, previous, history]);

  return (
    <Grid
      items={[
        {
          id: 'lake-games--signup',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: signupForm,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Signup);
