import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import './style.scss'

export default function Footer() {
    return (
        <>
            <div className="footer-basic">
                <footer>
                    <div className="social">
                        <a href="#"><InstagramIcon className='social__icon'/></a>
                        <a href="#"><TwitterIcon className='social__icon'/></a>
                        <a href="#"><FacebookIcon className='social__icon'/></a>
                    </div>
                    <p className="copyright">Created by <strong>Bret and Walker O'Brien</strong>. All rights reserved.
                        Powered by <a className='tpl__platform-link' href="">TBL Cloud Platform</a></p>
                </footer>
            </div>
        </>

    );
}
