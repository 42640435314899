import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss'

import { DataTable } from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import {useGroups} from "../../state-management";
import {GroupMember} from "../../state-management/types";
import {getTimeDiff} from "../../utils";
import {Loader, Message} from "../index";
import {AxiosResponse} from "axios";


interface MembersTableProps {
  groupId: string;
}

const MembersTable: React.FC<MembersTableProps> = ({ groupId }) => {

  const { state, getGroupMembers, addGroupMember, updateGroupMember, removeGroupMember } = useGroups()
  
  const [memberList, setMemberList] = useState<GroupMember[]>([]);
  const [memberDialog, setMemberDialog] = useState(false);
  const [deleteMemberDialog, setDeleteMemberDialog] = useState(false);
  const [deleteMembersDialog, setDeleteMembersDialog] = useState(false);
  const [member, setMember] = useState<GroupMember>();
  const [selectedMembers, setSelectedMembers] = useState<GroupMember[]>([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  
  const [refresh, setRefresh] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  
  
  React.useEffect(() => {
    getGroupMembers(groupId).then((res: AxiosResponse) => {
      setMemberList(res?.data?.results ?? [])
    })
  }, [refresh])
  
  const openNew = () => {
    setMemberDialog(true);
    setErr('')
  }
  
  const hideDialog = () => {
    setRefresh(!refresh)
    setMemberDialog(false);
    setUsername('')
    setEmail('');
    setPhone('');
  }
  
  const hideDeleteMemberDialog = () => {
    setDeleteMemberDialog(false);
  }
  
  const hideDeleteMembersDialog = () => {
    setDeleteMembersDialog(false);
  }
  
  const saveMember = async () => {
    
    if (member?.id) {
      if (toast?.current) {
        const res = await updateGroupMember(member.id, username, email, phone).catch((err: any) => {
          setErr(err)
        })
        console.log('GROUP CREATE RES:', res)
        // @ts-ignore
        if (res) {
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Member Updated', life: 3000 });
          hideDialog()
        }
      }
    }
    else {
      const res = await addGroupMember(groupId, username, email, phone).catch((err) => {
        setErr(err)
      })
      console.log('GROUP CREATE RES:', res)
      // @ts-ignore
      if (res) {
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Group Member Added', life: 3000 });
        hideDialog()
      }
      
      setMemberDialog(false);
      setMember(undefined);
    }
  }
  
  const editMember = (member: any) => {
    setMember({...member});
    setMemberDialog(true);
  }
  
  const confirmDeleteMember = (member: any) => {
    setMember(member);
    setDeleteMemberDialog(true);
  }
  
  const deleteMember = async () => {
    let _memberList = memberList.filter(val => val.id !== member?.id);
    setMemberList(_memberList);
    setDeleteMemberDialog(false);
    if (toast?.current) {
      console.log("DELETE MEMBER:", member)
      const res = await removeGroupMember(String(member?.id))
      if (res?.status === 204) {
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Group Member Removed', life: 3000 });
        setMember(undefined);
      }
      else {
        // @ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Removing Group Member', life: 3000 });
      }
      hideDialog()
    }
  }
  
  const exportCSV = () => {
    // @ts-ignore
    dt?.current?.exportCSV();
  }
  
  const handlePayout = () => {
    // @ts-ignore
    console.log("GO TO PAYOUT WITH THIS GROUP")
  }
  
  const confirmDeleteSelected = () => {
    setDeleteMembersDialog(true);
  }
  
  const deleteSelectedMembers = () => {
    let _memberList = memberList.filter((val: GroupMember) => {
      // @ts-ignore
      return !selectedMembers?.includes(val);
    });
    setMemberList(_memberList);
    setDeleteMembersDialog(false);
    setSelectedMembers(_memberList);
    if (toast?.current) {
      
      // @ts-ignore
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Members Deleted', life: 3000 });
    }
  }
  
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
        <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedMembers || !selectedMembers?.length} />
      </React.Fragment>
    )
  }
  
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Link to='/home'>
          <Button label="Run Payout" icon="pi pi-upload" className="p-button-help" onClick={handlePayout} />
        </Link>
      </React.Fragment>
    )
  }
  
  const usernameBodyTemplate = (rowData: GroupMember) => {
    return rowData.owner?.username
  }
  
  const emailBodyTemplate = (rowData: GroupMember) => {
    return rowData.owner?.email ? rowData.owner?.email : "N / A"
  }
  
  const phoneBodyTemplate = (rowData: GroupMember) => {
    return rowData.owner?.phone ? rowData.owner?.phone : "N / A"
  }
  
  const createdBodyTemplate = (rowData: GroupMember) => {
    return getTimeDiff(rowData.CreatedAt ?? '')
  }
  
  const actionBodyTemplate = (rowData: GroupMember) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editMember(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteMember(rowData)} />
      </React.Fragment>
    );
  }
  
  const header = (
    <div className="table-header">
      <h5 className="mx-0 my-1">Manage Members</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => {
          // @ts-ignore
          setGlobalFilter(e.target.value)
        }} placeholder="Search..." />
      </span>
    </div>
  );
  
  const memberDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={saveMember} />
    </React.Fragment>
  );
  
  const deleteMemberDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteMemberDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteMember} />
    </React.Fragment>
  );
  
  const deleteMembersDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteMembersDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedMembers} />
    </React.Fragment>
  );
  
  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />
      
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}/>
        
        <DataTable ref={dt} value={memberList} selection={selectedMembers} onSelectionChange={(e) => setSelectedMembers(e.value)}
                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Members"
                   globalFilter={globalFilter} header={header} responsiveLayout="scroll">
          <Column selectionMode="multiple" headerStyle={{width: '3rem'}} exportable={false}/>
          <Column field="username" header="Username" body={usernameBodyTemplate} sortable style={{minWidth: '6rem'}}/>
          <Column field="email" header="Email" body={emailBodyTemplate} sortable style={{minWidth: '6rem'}}/>
          <Column field="phone" header="Phone Number" body={phoneBodyTemplate} sortable style={{minWidth: '6rem'}}/>
          <Column field="created" header="Added" body={createdBodyTemplate} sortable style={{minWidth: '4rem'}}/>
          <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}/>
        </DataTable>
      </div>
      
      <Dialog visible={memberDialog} style={{ width: '450px' }} header="Add to Group" modal className="p-fluid" footer={memberDialogFooter} onHide={hideDialog}>
        
        <div className="field">
          <label htmlFor="name">Username</label>
          <InputText id="name" value={username} autoFocus onChange={(e) => setUsername(e?.target?.value ?? '')} />
        </div>
        
        <div className="field">
          <label htmlFor="email">Email</label>
          <InputText id="email" value={email} onChange={(e) => setEmail(e?.target?.value ?? '')} />
        </div>
        
        <div className="field">
          <label htmlFor="phone">Phone Number</label>
          <InputText id="phone" value={phone} onChange={(e) => setPhone(e?.target?.value ?? '')} />
        </div>
        
        <Loader loading={state.loading} />
        <Message message={err} severity={'error'}/>
      </Dialog>
      
      <Dialog visible={deleteMemberDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteMemberDialogFooter} onHide={hideDeleteMemberDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {member && <span>Are you sure you want to delete member <b>{member?.owner?.username}</b>?</span>}
        </div>
      </Dialog>
      
      <Dialog visible={deleteMembersDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteMembersDialogFooter} onHide={hideDeleteMembersDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {member && <span>Are you sure you want to delete the selected list of members?</span>}
        </div>
      </Dialog>
    </div>
  );
};

export default MembersTable;
