import { createContext } from 'react';
import { PayoutDispatch } from './reducer';

export interface PayoutState {
  results: any
  msg: string
  emailMsg: string
  smsMsg: string
  winnerMsg: string
  loserMsg: string
  recapMsg: string
  error: string
  emailErr: string
  smsErr: string
  loading: boolean
}

export interface PayoutContextState {
  state: PayoutState
  dispatch: PayoutDispatch
}

export const defaultState = (): PayoutState => ({
  results: '',
  msg: '',
  winnerMsg: '',
  loserMsg: '',
  recapMsg: '',
  emailMsg: '',
  smsMsg: '',
  error: '',
  emailErr: '',
  smsErr: '',
  loading: false,
});

export const Context = createContext<PayoutContextState>({
  state: defaultState(),
  dispatch: () => null,
});
