import { Dispatch } from 'react';
import { GroupsState, defaultState } from './context';
import {getError, translateError} from '../../utils';
import {
  Group, GroupMember, GroupResponse,
} from '../types';

export type GroupsAction =
  | { type: 'SET_GROUPS'; currentGroup: GroupResponse; groups: GroupResponse[] }
  | { type: 'START_GROUPS' }
  | { type: 'CREATE_GROUP'; group: Group }
  | { type: 'ADD_MEMBER'; msg: string }
  | { type: 'UPDATE_MEMBER'; msg: string }
  | { type: 'ERROR_GROUPS'; error: string }
  | { type: 'CLEAR_GROUPS' }
  | { type: 'RESET_GROUPS' };

export type GroupsDispatch = Dispatch<GroupsAction>;

export const reducer = (
  state: GroupsState,
  action: GroupsAction
): GroupsState => {
  switch (action.type) {
    case 'START_GROUPS': {
      return { ...state, error: '', loading: true };
    }

    case 'ERROR_GROUPS': {
      const { error } = action;
      const parsedError = translateError(getError(error));
      return { ...state, error: parsedError, loading: false };
    }

    case 'SET_GROUPS': {
      const { currentGroup, groups } = action;

      if (currentGroup) localStorage.setItem('currentGroup', JSON.stringify(currentGroup));
      if (groups) localStorage.setItem('groups', JSON.stringify(groups));

      return {
        ...state,
        error: '',
        loading: false,
        currentGroup,
        groups,
      };
    }
    
    case 'ADD_MEMBER': {
      const { msg } = action;
    
      return {
        ...state,
        error: '',
        loading: false,
        msg,
      };
    }

    case 'RESET_GROUPS': {
      const currentGroup = JSON.parse(String(localStorage.getItem('currentGroup') || {})) as GroupResponse

      const groups = JSON.parse(
        String(localStorage.getItem('groups') || {})
      ) as GroupResponse[];

      return {
        ...state,
        currentGroup,
        groups,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
