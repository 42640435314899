import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Paper, Toolbar, Tabs, Tab } from '@material-ui/core'
import { InputForm } from '../../forms';
import {Example, Footer} from '../../components';
import HomeHeader from '../../components/home-header';
import './styles.scss';

const Home: React.FC<RouteComponentProps> = () => {

  const [value, setValue] = React.useState<number>(0)

  let component = null

  switch (value) {
    case 0:
      component = <InputForm/>
      break

    case 1:
      component = <Example/>
      break

    default:
      console.log('Error tab value')
      break
  }

  return (
    <div className='lg-home'>
      <HomeHeader/>
      <div className='tool__bar-container'>
        <Toolbar className='tool__bar'>
          <Paper className='user-paper'>
            <Tabs
                value={value}
                indicatorColor='primary'
                textColor='primary'
                onChange={(e, newVal) => setValue(newVal)}
                aria-label='user-tabs'
                className='user-tabs'
            >
              <Tab label='Input Form' className='tab__item' />
              <Tab label='Example input' className='tab__item' />
            </Tabs>
          </Paper>
        </Toolbar>
      </div>
      {component}

      <Footer/>
    </div>
  );
};

export default withRouter(Home);
