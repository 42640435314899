import { useContext } from 'react';
import { Context, PayoutState } from './context';
import useHttp from "../http";
import {GroupMember} from "../types";

export interface UsePayout {
  (): {
    calculate: (inputMethod: string, players: GroupMember[], groupId: number) => Promise<void>;
    
    refresh: () => Promise<void>;

    state: PayoutState;
  };
}

export const usePayout: UsePayout = () => {
  const { state, dispatch } = useContext(Context);
  const http = useHttp();
  
  const calculate = async (input_method: string, playersIn: GroupMember[], group_id: number): Promise<any> => {
    dispatch({
      type: 'START_PAYOUT',
    });
  
    console.log("PLAYERS IN:", playersIn)
    
    const emails = playersIn.map((player: GroupMember) => (player.owner.email)).filter(Boolean).join(';');
    const phone_numbers = playersIn.map((player: GroupMember) => (player.owner.phone)).filter(Boolean).join(';');
    const players = playersIn.map((player: GroupMember) => (`${player.score} ${player.owner.username}`)).join(';');
  
    const data = {
      input_method,
      emails,
      phone_numbers,
      players,
      group_id
    }
    
    const res = await http.postData('payout', data)
    if (res?.status === 200) {
      dispatch({
        type: 'SET_PAYOUT',
        results: res.data?.results?.Results,
        winnerMsg: res.data?.results?.WinnerMessage,
        loserMsg: res.data?.results?.LoserMessage,
        recapMsg: res.data?.results?.RecapMessage,
        msg: 'Successfully queried payout API',
      });
    }
    else {
      console.log("ERROR RES:", res.data)
      dispatch({
        type: 'ERROR_PAYOUT',
        error: res.data.error || res.data.results?.Error,
        emailErr: '',
        smsErr: ''
      });
    }
    return res
  };

  const refresh = async (): Promise<void> => {
    dispatch({
      type: 'RESET_PAYOUT',
    });
  };

  return {
    calculate,
    refresh,
    state,
  };
};
