import { createContext } from 'react';
import { GroupsDispatch } from './reducer';
import {
  Group, GroupResponse,
} from '../types';

export interface GroupsState {
  currentGroup?: GroupResponse | undefined,
  groups: GroupResponse[],
  msg: string;
  error: string;
  loading: boolean;
}

export interface GroupsContextState {
  state: GroupsState;
  dispatch: GroupsDispatch;
}

export const defaultState = (): GroupsState => ({
  currentGroup: undefined,
  groups: [],
  msg: '',
  error: '',
  loading: false,
});

export const Context = createContext<GroupsContextState>({
  state: defaultState(),
  dispatch: () => null,
});
