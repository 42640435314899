import * as React from 'react';
import { Header } from '..';
import './styles.scss';
import {useAuth} from "../../state-management";
import {User} from "../../state-management/types";

const Layout: React.FC = ({ children }) => {
  const { state, getUser } = useAuth()
  console.log('STATE:', state)
  
  React.useEffect(() => {
    const user = JSON.parse(String(localStorage.getItem("user") ?? "{}")) as User
    console.log("CURRENT USER:", user)
    if (user?.id)
      getUser(String(user?.id)).catch((err) => console.log('ERROR GETTING USER:', err))
  }, [])
  
  return (
    <>
      <Header />
      <div className='lake-games--layout'>{children}</div>
    </>
  );
};

export default Layout;
