import { Dispatch } from 'react';
import { AuthState, defaultState } from './context';
import {getError, translateError} from '../../utils';
import {
  User,
} from '../types';
import LogRocket from "logrocket";

export type AuthAction =
  | { type: 'SET_AUTHENTICATION'; logname: string; user: User, token: string }
  | { type: 'START_AUTHENTICATION' }
  | { type: 'CLEAR_AUTHENTICATION' }
  | { type: 'RESET_AUTHENTICATION' }
  | { type: 'START_SUPPORT_EMAIL' }
  | { type: 'SUPPORT_EMAIL'; msg: string }
  | { type: 'ERROR'; error: string }

export type AuthDispatch = Dispatch<AuthAction>;

export const reducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'START_AUTHENTICATION': {
      return { ...state, error: null, loading: true };
    }
    
    case 'START_SUPPORT_EMAIL': {
      return { ...state, error: null, loading: true };
    }
    
    case 'ERROR': {
      const { error } = action;
      const parsedError = translateError(getError(error));
      return { ...state, error: parsedError, loading: false };
    }

    case 'SET_AUTHENTICATION': {
      const { logname, user, token } = action;
      const exp = new Date(new Date().getTime() + 3600 * 1000).toString();
      console.log("AUTH ACTION:", action)

      localStorage.setItem('logname', logname ?? '');
      localStorage.setItem('user', JSON.stringify(user ?? null));
      localStorage.setItem('token', token ?? '');
      localStorage.setItem('exp', exp);
  
      if (user)
        LogRocket.identify(String(user.id), {
          name: user.username,
          email: user.email ?? '',
        });

      return {
        ...state,
        error: '',
        loading: false,
        isAuthenticated: true,
        logname,
        user,
        token,
        exp,
      };
    }
  
    case 'SUPPORT_EMAIL': {
      const { msg } = action;
      
      return {
        ...state,
        error: '',
        loading: false,
        msg,
      };
    }
    
    case 'CLEAR_AUTHENTICATION': {
      localStorage.clear();
      return defaultState();
    }

    case 'RESET_AUTHENTICATION': {
      const logname = localStorage.getItem('logname') ?? ''

      const user = JSON.parse(
        String(localStorage.getItem('user') || {})
      ) as User;
  
      const token = localStorage.getItem('token') ?? ''
      
      const exp = localStorage.getItem('exp') ?? ''

      return {
        ...state,
        logname,
        loading: false,
        user,
        token,
        exp,
        isAuthenticated: Boolean(user?.id),
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
