import * as React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Card, Checkbox } from '@mui/material';
import './styles.scss'

const Example: React.FC = () => {
  
  return (
    <>
  
    <Card>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3} m={3} xs={3}>
            <h3>Simple Batch Example:</h3>
            <h5>Walker 10</h5>
            <h5>Bret -10</h5>
          </Grid>
          
          <Grid item xl={3} lg={3} m={3} xs={3}>
            <h3>Complex Batch Example:</h3>
            <h5>Vince 1000</h5>
            <h5>Garrett -1556</h5>
            <h5>Tzetzo -165</h5>
            <h5>Falcon 3579</h5>
            <h5>Medow 9871</h5>
            <h5>Walker 21721</h5>
            <h5>Coury -2000</h5>
            <h5>Richie -3075</h5>
            <h5>Lap -7183</h5>
            <h5>Jimmy -2563</h5>
            <h5>Lawson -925</h5>
            <h5>BigBank 3955</h5>
            <h5>Hendo -35659</h5>
            <h5>Sunny 13000</h5>
          </Grid>
          
        </Grid>
      </Box>
    </Card>
    
    </>
  )
}

export default Example
