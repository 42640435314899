import * as React from 'react'
import {Button, Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Message,
  Loader,
  TextField,
} from '../../components'
import {validateEmail, validatePhone} from '../../utils';
import {GroupMember, User} from '../../state-management/types';
import './styles.scss'

interface PlayerFormProps {
  id: number
  inputMethod: string
  open?: boolean
  addPlayer?: any
  edit?: boolean
  activePlayer?: GroupMember
  setRender?: any
  showContactInfoFields?: boolean
}

const PlayerForm: React.FC<PlayerFormProps> = ({id, inputMethod, open, addPlayer, edit, activePlayer, setRender, showContactInfoFields}) => {

  const [isOpen, setIsOpen] = React.useState<boolean>(open || false)
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [msg, setMsg] = React.useState<string>('')

  const [username, setUsername] = React.useState<string>(activePlayer?.owner.username || '')
  const [email, setEmail] = React.useState<string>(activePlayer?.owner.email || '')
  const [phone, setPhone] = React.useState<string>(activePlayer?.owner.phone || '')
  const [score, setScore] = React.useState<string>(String(activePlayer?.score || ''))

  const handleSubmit = () => {
    const playerOwner: User = {
      id: -1,
      username: username.trim(),
      email: email.trim(),
      phone: phone.trim(),
    }
  
    const player = {
      id,
      owner: playerOwner,
      score: score.trim().replace(',', ''),
    }

    if (!username) {
      setError('Must provide player name')
      return
    }
    if (email && !validateEmail(email.trim())) {
      setError('Invalid email address')
      return
    }
    if (phone && !validatePhone(phone.trim())) {
      setError('Invalid phone number')
      return
    }
    if (inputMethod === 'individual' && !Number(score.trim().replace(',', ''))) {
      setError('Invalid player score')
      return
    }

    if (addPlayer) addPlayer(player)
    if (setRender) setRender()
    handleClick()
  }

  const handleClick = () => {
    if (isOpen && !edit) clearForm()
    setIsOpen(!isOpen);
  };

  const clearForm = () => {
    setLoading(false)
    setMsg('')
    setError('')
    setUsername('')
    setScore('')
    setEmail('')
    setPhone('')
  }

  let title = 'Add Player'
  let buttonClassName = 'dialog-box--btn'
  let buttonClasses = {label: 'dialog-box--btn-label'}

  if (edit) {
    title = 'Edit'
    buttonClassName = 'edit-dialog-box--btn'
    buttonClasses = {label: 'edit-dialog-box--btn-label'}
  }

  return (
    <>
      <Button
        className={buttonClassName}
        variant='outlined'
        color='primary'
        onClick={handleClick}
        classes={buttonClasses}
      >
        {title === 'Edit'?<ModeEditIcon/>:title }
      </Button>
      <Dialog
        onClose={handleClick}
        className='dialog-box'
        aria-labelledby='simple-dialog-title'
        open={isOpen}
        fullWidth
      >
        <DialogTitle
          disableTypography
          className='dialog-box--title'
          id='simple-dialog-title'
        >
          {title}
        </DialogTitle>
        <DialogContent className='dialog-box--content'>
          <div className='lg-video-form'>
            <TextField
              value={username}
              id='username'
              label='Username'
              autoComplete='username'
              onChange={(e) => setUsername(e.target.value)}
              handleSubmit={handleSubmit}
            />

            <TextField
              value={score}
              id='score'
              label='Player Score'
              autoComplete='score'
              onChange={(e) => setScore(e.target.value)}
              handleSubmit={handleSubmit}
            />
  
            {showContactInfoFields ? (
              <>
                <TextField
                  value={email}
                  id='email'
                  label='Email'
                  autoComplete='email'
                  onChange={(e) => setEmail(e.target.value)}
                  handleSubmit={handleSubmit}
                />
  
                <TextField
                  value={phone}
                  id='phone'
                  label='Phone Number'
                  autoComplete='phone'
                  onChange={(e) => setPhone(e.target.value)}
                  handleSubmit={handleSubmit}
                />
              </>
            ) : null}

            <Message
              severity={error ? 'error' : 'success'}
              message={error || msg}
            />

            <Loader loading={loading}/>
            <Button
              variant='outlined'
              color='primary'
              onClick={handleSubmit}
              className='lg-video-form--btn'
              classes={{
                label: 'lg-player-form--btn-label',
              }}
            >
              {edit ? 'Update' : 'Add'}
            </Button>
            <Button
              className='dialog-box--btn'
              variant='outlined'
              color='primary'
              onClick={handleClick}
              classes={{label: 'dialog-box--btn-label'}}
            >
              Close
            </Button>

          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PlayerForm
