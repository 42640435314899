import * as React from 'react'
import { Link } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from '@material-ui/core'
import Delete from '@mui/icons-material/Delete';
import { Card } from '@mui/material';
import { PlayerForm } from '..'
import {Message, Loader, TextField, SelectField, PayoutItem} from '../../components'
import {useAuth, useGroups, usePayout} from "../../state-management";
import {GroupResponse, GroupMember} from '../../state-management/types';
import {LIFECYCLE, serializePlayers} from '../../utils';
import './styles.scss'

const InputForm: React.FC = () => {

  const auth = useAuth()
  const payout = usePayout()
  const groups = useGroups()
  
  const [playerData, setPlayerData] = React.useState<GroupMember[]>([])

  const inputMethods = ['batch', 'sheets', 'individual']
  const [inputMethod, setInputMethod] = React.useState<string>(inputMethods[0])
  
  // @ts-ignore
  const [currentGroup, setCurrentGroup] = React.useState<GroupResponse>(groups.state.currentGroup)

  const [playerString, setPlayerString] = React.useState<string>('')

  const [error, setError] = React.useState<string>('')

  const [render, setRender] = React.useState(false)
  const [initialHide, setInitialHide] = React.useState(true)
  
  const spreadsheetId = LIFECYCLE === "prod" ? "1dylxhPJxJ7dc8k9kS47ii85F4GBNsCTnE-C4KDg0vSs" : "1U0RDISTzMWMfrSAI_UGgNATyEA6E9HIKmjc-JH1UDKs"
  
  React.useEffect(() => {
    if (groups.state.currentGroup) setPlayerData(groups.state.currentGroup.members)
  }, [auth.state.isAuthenticated, groups.state.currentGroup?.group?.id, render])
  
  const addPlayer = (player: GroupMember) => {
    if (player.id === playerData.length)
      setPlayerData(playerData.concat(player))
    else {
      const tmpPlayerData = playerData
      tmpPlayerData[player.id] = player
      setPlayerData(tmpPlayerData)
    }
  }

  const removePlayer = (id: number) => {
    const tmpData = playerData
    tmpData.splice(id, 1)
    setPlayerData(tmpData)
    setRender(!render)

  }

  const handleSubmit = async () => {
    setError('')
    setInitialHide(false)

    if (inputMethod === 'batch') {
      setPlayerData(serializePlayers(playerString))
      setRender(!render)
    }

    if (inputMethod === 'batch' && !playerString)
      setError('Missing player input data')
    if (inputMethod === 'individual' && playerData?.length < 1)
      setError('Missing player input data')
    else {
      let players = playerData
      if (inputMethod === 'batch') {
        players = serializePlayers(playerString)
      }
      const res = await payout.calculate(inputMethod, players, currentGroup?.group?.id || 0)
      // @ts-ignore
      if (res?.error) setError(res.error)
    }
  }

  console.log("PLAYER DATA:", playerData)
  const userList = playerData.length > 0 ? (
    <Box sx={{ width: '100%' }}>
      <br/>
      <h2>Player list:</h2>
      <Stack spacing={2}>
        {playerData?.map((player: GroupMember, index: number) => (
          <Paper className='player__list-box' key={index}>
            <div>
              <div className='player__box'>
                <strong>{player.owner.username} &nbsp;: &nbsp;
                  <span className={(player.score && Math.sign(Number(player.score)) === 1) ? 'text--green': 'text--red'}>
                    {player?.score}
                  </span>
                </strong>
              </div>
              <div className='player__button'>
              <PlayerForm id={index} showContactInfoFields={!Boolean(groups.state.currentGroup)} setRender={() => setRender(!render)} activePlayer={player} inputMethod={inputMethod} edit={true} addPlayer={addPlayer}/>

              <Button
                className='dialog-box--btn'
                variant='outlined'
                color='secondary'
                onClick={() => removePlayer(player.id)}
              >
                <Delete />
              </Button>
              </div>
            </div>
          </Paper>
        ))}
      </Stack>
    </Box>
  ) : null
  
  console.log("CURRENT GROUP:", currentGroup)
  console.log("PAYOUT STATE:", payout.state)
  
  const formatAiMessage = (message: string) => {
    const splitMsg = message.split("\n")
    return splitMsg.map((line: string) => (
      <p>{line}</p>
    ))
  }
  
  return (
    <Card>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className='input__form-grid-container'>
          <Grid item xl={3} lg={4} m={5} xs={10} className='input__form-grid'>
            <h2>Expected Input</h2>
            {inputMethod === 'batch' ? (
                <>
                  <ol className="gradient-list">
                    <li>Enter one line for each player with a space in between the player's name, final total</li>
                  </ol>
                </>
            ) : null}
            {inputMethod === 'individual' ? (
                <>
                  <ol className="gradient-list">
                    <li>Use the "Add Player" button to add a player's name and final total</li>
                  </ol>
                </>
            ) : null}
            {inputMethod === 'sheets' ? (
              <>
                <ol className="gradient-list">
                  <li><Link to='/signup'>Sign up</Link> and create a group in the <Link to='/groups'>groups page</Link></li>
                  <li>
                    Request access to the shared (for now)
                    <a href={`https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit#gid=0`}> Google sheet</a>
                    . Please don't mess with other group's sheets while this is still under development!
                  </li>
                  <li>Add players to group in groups page</li>
                  <li>Enter player's scores in the <a href={`https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit#gid=0`}>sheet</a> and press submit below</li>
                </ol>
              </>
            ) : null}
  
            <SelectField
              label='Input Method'
              required
              value={inputMethod}
              values={inputMethods}
              setValue={setInputMethod}
            />
            
            {inputMethod === 'batch' ? (
              <TextField
                value={playerString}
                id='players'
                label='Game Results'
                multiline
                autoComplete='players'
                onChange={(e) => setPlayerString(e.target.value)}
                handleSubmit={handleSubmit}
              />
            ) : null}
            
            {inputMethod === 'sheets' ? (
              <>
                <SelectField
                  label='Groups'
                  value={currentGroup}
                  values={groups.state.groups}
                  setValue={setCurrentGroup}
                />
                <iframe src={`https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit?usp=sharing`} width="750" height="500"/>
              </>

            ) : null}
            
            <br />
            <br />
  
            <div className='input__form-buttons'>
              {inputMethod === 'individual' ? (
                <PlayerForm id={playerData.length} setRender={() => setRender(!render)} inputMethod={inputMethod} addPlayer={addPlayer}/>
              ) : null}
              
              <Button variant='outlined' color='primary' onClick={handleSubmit}>
                <div>Submit</div>
              </Button>
            </div>
            <br/>
            
            {currentGroup ? (
              <h1>Current Group: {currentGroup.group.name}</h1>
            ) : null}
            
            {inputMethod === 'individual' ? (
              <>{userList}</>
            ) : null}
          </Grid>
  
          
          {!initialHide ? (
            <Grid item xl={4} lg={5} m={6} xs={10} className='payout__grid'>
              <h2>Payout results:</h2>
              <Loader loading={payout.state.loading} />
              
              {!payout.state.loading && !(error || payout.state.error) && !payout.state.results ? (
                <Message severity='info' message='No results to show'/>
              ) : null}
  
              <Message severity={error || payout.state.error ? 'error' : 'success'} message={error || payout.state.error || payout.state.msg} />
              <Message severity={payout.state.results?.emailError ? 'error' : 'success'} message={payout.state.results?.emailError || payout.state.results?.emailMsg} />
              <Message severity={payout.state.results?.smsError ? 'error' : 'success'} message={payout.state.results?.smsError || payout.state.results?.smsMsg} />

              {payout.state.results?.split('\n').map((item: string, index: number) => (
                <PayoutItem key={index} item={item}/>
              ))}
              {payout.state.recapMsg ? <div><strong>AI Recap: </strong>{formatAiMessage(payout.state.recapMsg)}</div> : null}
              {payout.state.winnerMsg ? <div><strong>AI Ideas to spend the winnings: </strong>{formatAiMessage(payout.state.winnerMsg)}</div> : null}
              {payout.state.loserMsg ? <div><strong>AI Ideas to earn money: </strong>{formatAiMessage(payout.state.loserMsg)}</div> : null}
            </Grid>
          ) : null}

        </Grid>
      </Box>
    </Card>
  )
}

export default InputForm
