import * as React from 'react';
import {
  HiOutlineQuestionMarkCircle,
  HiOutlineChevronRight,
  HiClipboardCheck,
} from 'react-icons/hi';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TextField as MuiTextField } from '@mui/material'
import GoogleButton from 'react-google-button';
import {Grid, IconButton, Loader, Message, TextField} from '../../components';
import {CURRENT_URL, LIFECYCLE} from '../../utils';
import { useAuth } from '../../state-management';
import './styles.scss';

interface LoginProps {
  previous: {
    location: string;
  };
}

const Login: React.FC<LoginProps & RouteComponentProps> = ({
  history,
  previous,
}) => {

  const { state, login } = useAuth()
  
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const onLogin = async () => {
    await login(username, email, password)
  };
  
  const loginForm = (
    <div className='lake-games--login'>
      <div className='lake-games--login-header'>Login</div>

      <Message severity={state.error ? 'error' : 'success'} message={state.error || state.msg} />
      <TextField
        value={username}
        id='username'
        label='Username'
        autoComplete='username'
        onChange={(e) => setUsername(e.target.value)}
        handleSubmit={onLogin}
      />
  
      <TextField
        value={email}
        id='email'
        label='Email'
        autoComplete='email'
        type='email'
        onChange={(e) => setEmail(e.target.value)}
        handleSubmit={onLogin}
      />
      <TextField
        value={password}
        id='password'
        label='Password'
        type='password'
        onChange={(e) => setPassword(e.target.value)}
        handleSubmit={onLogin}
      />
      <div className='lake-games--login-actions'>
        <Loader loading={state.loading} />
        <IconButton
          tooltipTitle='Login'
          icon={HiOutlineChevronRight}
          onClick={onLogin}
          disabled={!username.length || !password.length}
        />
        <IconButton
          tooltipTitle='Forgot Password'
          icon={HiOutlineQuestionMarkCircle}
          onClick={() => history.push('/forgot')}
        />
        <IconButton
          tooltipTitle='Signup'
          icon={HiClipboardCheck}
          onClick={() => history.push('/signup')}
        />
        {LIFECYCLE !== "prod" ? (
          <a className='google-login' href={`${CURRENT_URL}accounts/google/login/?process=login`}>
            <GoogleButton />
          </a>
        ) : null}
      </div>
    </div>
  );

  React.useEffect(() => {
    if (state.isAuthenticated) {
      history.push(previous?.location || '/home');
    }
  }, [state.isAuthenticated, previous, history]);

  return (
    <Grid
      items={[
        {
          id: 'lake-games--login',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: loginForm,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Login);
