import * as React from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Loader, Message, Grid, TextField, IconButton } from '../../components';
import { useAuth } from '../../state-management';
import './styles.scss';

interface SupportProps {
  previous: {
    location: string;
  };
}

const Support: React.FC<SupportProps & RouteComponentProps> = () => {
  const { state, supportEmail } = useAuth();

  console.log('AUTH STATE:', state)
  
  const [message, setMessage] = React.useState('');

  const handleSubmit = async () => {
    await supportEmail(message)
  };

  const supportForm = (
    <div className='lake-games--support'>
      <div className='lake-games--support-header'>TP2 Support</div>

      <TextField
        value={message}
        id='message'
        label='Message'
        onChange={(e) => setMessage(e.target.value)}
        handleSubmit={handleSubmit}
      />
      <div className='lake-games--support-actions'>
        <Loader loading={state.loading} />
        <IconButton
          tooltipTitle='TP2 Support'
          icon={HiOutlineChevronRight}
          onClick={handleSubmit}
          disabled={!message.length}
        />
      </div>
      <Message
        severity={state.error ? 'error' : 'success'}
        message={state.error || state.msg}
      />

    </div>
  );

  return (
    // @ts-ignore
    <Grid
      items={[
        {
          id: 'lake-games--support',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: supportForm,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Support);
