import React from 'react';
import LogRocket from 'logrocket';
import BaseRouter from './utils/router';
import { Layout } from './components';
import {LIFECYCLE} from "./utils";

if (LIFECYCLE === 'prod') LogRocket.init('n0phks/prod-tp2');

declare global {
  interface Window {
    LIFECYCLE: string,
    APP_CODE: string;
    API_URL: string;
    STATIC_BUCKET: string;
  }
}

const App: React.FC = () => (
  <div className='lake-games'>
    <Layout>
      <BaseRouter />
    </Layout>
  </div>
);

export default App;

