import { Dispatch } from 'react';
import { PayoutState } from './context';
import {getError, translateError} from '../../utils';

export type PayoutAction =
  | { type: 'SET_PAYOUT'; results: string, msg: string, winnerMsg: string, loserMsg: string, recapMsg: string }
  | { type: 'START_PAYOUT' }
  | { type: 'ERROR_PAYOUT'; error: string, emailErr: string, smsErr: string }
  | { type: 'CLEAR_PAYOUT' }
  | { type: 'RESET_PAYOUT' };

export type PayoutDispatch = Dispatch<PayoutAction>;

export const reducer = (
  state: PayoutState,
  action: PayoutAction
): PayoutState => {
  switch (action.type) {
    case 'START_PAYOUT': {
      return { ...state, error: '', loading: true, msg: '' };
    }

    case 'ERROR_PAYOUT': {
      const { error } = action;
      const parsedError = translateError(getError(error));
      return { ...state, error: parsedError, loading: false };
    }

    case 'SET_PAYOUT': {
      const { results, msg, winnerMsg, loserMsg, recapMsg } = action;

      localStorage.setItem('payout', JSON.stringify(results));
      localStorage.setItem('msg', JSON.stringify(msg));
      localStorage.setItem('recapMsg', JSON.stringify(recapMsg));
      localStorage.setItem('winnerMsg', JSON.stringify(winnerMsg));
      localStorage.setItem('loserMsg', JSON.stringify(loserMsg));

      return {
        ...state,
        error: '',
        loading: false,
        results,
        recapMsg,
        winnerMsg,
        loserMsg,
        msg,
      };
    }

    case 'RESET_PAYOUT': {
      const results = String(localStorage.getItem('payout') || {})

      return {
        ...state,
        results,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
