import * as React from 'react';
import { RouteComponentProps, withRouter, useParams } from 'react-router-dom';
import {Grid, MembersTable} from '../../components';
import './styles.scss';

interface MembersParamType {
   id: string
}

interface MembersProps {
  history: any
  previous: {
    location: string
  }
}

const Members: React.FC<MembersProps & RouteComponentProps> = ({ history, previous }) => {
  
  const { id } = useParams<MembersParamType>()

  return (
    <Grid
      items={[
        {
          id: 'lake-games--signup',
          cols: { xs: 12, sm: 12, md: 12, lg: 10 },
          content: <MembersTable groupId={id}/>,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Members);
