import * as React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import {Menu} from "../index";
import './styles.scss';

const Header: React.FC = () => {
  return (
    <>
      <AppBar
        position='fixed'
        className='lake-games--header'
        classes={{ colorPrimary: 'lake-games--header-primary' }}
      >
        <Menu/>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Header;
