import { useState } from 'react'
import axios, {AxiosResponse} from 'axios';
import {CURRENT_URL, fixURI} from '../../utils';


export default function useHttp() {
  const [loading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [error, setError] = useState<string>('');
  
  const api = () => {
    setLoading(true)
    setError('')
    setMsg('')
    // axios.defaults.withCredentials = true;
    // axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
    // axios.defaults.xsrfCookieName = 'csrftoken';
    
    const token = localStorage.getItem('token');
    if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    
    return axios.create({ baseURL: `${CURRENT_URL}api/`, timeout: 2000000 });
  };
  
  const getData = (url: string): Promise<any> => {
    return api()
    .get(fixURI(url))
    .then((res) => {
      console.log(`GET ${url} RES:`, res)
      setLoading(false)
      return res
    })
    .catch(({ response }) => {
      return response;
    });
  };
  
  
  const postData = async (url: string, data: any, headersIn?: any): Promise<any> => {
    const headers = headersIn ?? { headers: {'Content-Type': 'application/json'} }
    
    return api()
      .post(fixURI(url), data, headers)
      .then((res) => {
        console.log('POST RES:', res)
        setLoading(false)
        return res
      })
      .catch(({ response }) => {
        setLoading(false)
        return response;
      });
  };
  
  
  const putData = (url: string, data: any): Promise<any> => {
    return api()
    .put(fixURI(url), data)
    .then((res) => {
      setLoading(false)
      return res
    })
    .catch(({ response }) => {
      return response;
    });
  };
  
  
  const patchData = (url: string, data: any): Promise<any> => {
    return api()
    .patch(fixURI(url), data)
    .then((res) => {
      setLoading(false)
      return res
    })
    .catch(({ response }) => {
      return response;
    });
  };
  
  
  const deleteData = (url: string): Promise<AxiosResponse> => {
    return api().delete(fixURI(url))
      .then((res) => {
        setLoading(false)
        return res
      })
      .catch(({ response }) => {
        return response;
      });
  };
  
  
  return {
    getData,
    postData,
    putData,
    patchData,
    deleteData,
    loading,
    msg,
    error,
  }
}
