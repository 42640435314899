import * as React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './styles.scss'

interface PayoutItemProps {
    item: any;
}

const PayoutItem: React.FC<PayoutItemProps> = ({item}) => {

    const list = item.split(':')
    if (list.length !== 2) return null
    const num = list[1].trim()

    const names = list[0].split('->')
    if (names.length !== 2) return null

    return (
        <Paper className='player__list-box'>
            <div>
                <strong className="score__list">
                    <table className='score__list-table'>
                        <tbody>
                        <tr>
                          <td width='30%' className='payout-loser'>{names[0].trim()}</td>
                          <td width='10%'><ArrowRightAltIcon className="arrow--icon"/></td>
                          <td width='30%' className='payout-winner'>{names[1].trim()}</td>
                          <td width='10%'>:</td>
                          <td width='30%' className='payout-amount'>{num}</td>
                        </tr>
                        </tbody>
                    </table>
                </strong>
            </div>
        </Paper>
    )
}

export default PayoutItem