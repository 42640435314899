import * as React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import './styles.scss';
import {useAuth} from "../../state-management";

interface MenuProps {
  edge?: 'start' | 'end' | false;
}

interface MenuItemProps {
  to?: string;
  display: string | null;
  onClick?: () => void;
  icon?: any;
}

const MenuComponent: React.FC<MenuProps> = ({ edge }) => {
  
  const { state, logout } = useAuth()
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout()
    setAnchorEl(null);
  };

  const CustomMenuItem: React.FC<MenuItemProps> = ({
    to,
    display,
    onClick,
    icon: Icon
  }) => {
    const menuI = (
      <MenuItem
        className="lg--header-menu-list-item-lg"
        onClick={onClick || handleClose}
        key={display}
      >
        {Icon ? <Icon className="header-icon" /> : null}
        {display}
      </MenuItem>
    );

    return to ? <Link to={to} className="header-menu-link">{menuI}</Link> : <div className="header-menu-link">{menuI}</div>;
  };

  const loggedInContent = (
    <div className="menu__btn_lg">
      <CustomMenuItem icon={HomeIcon} to="/home" display="Home" />
      <CustomMenuItem icon={SearchIcon} to="/groups" display="Groups" />
      <CustomMenuItem icon={HelpOutlineIcon} to="/support" display="Support" />

      <CustomMenuItem icon={ExitToAppIcon} onClick={handleLogout} display="Logout" />
    </div>
  );

  const strangerContent = (
    <div className="menu__btn_lg">
      <CustomMenuItem icon={HomeIcon} to="/home" display="Home" />
      <CustomMenuItem icon={AssignmentTurnedInIcon} to="/login" display="Login" />
      <CustomMenuItem icon={PersonAddIcon} to="/signup" display="Signup" />
    </div>
  );

  return (
    <>
        {state.isAuthenticated ? loggedInContent : strangerContent}
    </>
  );
};

MenuComponent.defaultProps = {
  edge: false
};

export default MenuComponent;
